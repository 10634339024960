const debounce = require('debounce')

const handleClickToCloseFlyouts = function(ev) {
  const checkboxes = $("input[type='checkbox'][name='flyout']")
  const flyoutOpen = checkboxes.filter(":checked").length > 0
  if (flyoutOpen && $(ev.target).closest(".c12__section.as-flyout").length === 0) {
    checkboxes.prop("checked", false)
  }
}

const handleFilterUiFullscreen = function(checkbox) {
  $(checkbox).closest(".c12-search-filter").toggleClass("as-open", checkbox.checked)
  $("body").toggleClass("tw-overflow-hidden lg_tw-overflow-visible", checkbox.checked)
}

module.exports = (function() {
  // Flyout schließen, wenn außerhalb geklickt wird.
  // Debouncen ist insbesondere auch deswegen nötig, weil beim Klick auf eine Checkbox der Eventhandler aufgerufen wird, bevor sich der State der Checkbox geändert hat.
  $(document).on("click", debounce(handleClickToCloseFlyouts, 50))

  // „Akkordion-artiges“ Verhalten für die Flyouts: Beim Öffnen eines Flyouts (= Anklicken der entsprechenden Checkbox) alle anderen schließen.
  $(document).on("click", "input[type='checkbox'][name='flyout']", function() {
    $("input[type='checkbox'][name='flyout']").not(this).prop("checked", false)
  })

  // Fullscreen für Filter-UI auf Mobile
  $(document).on("change", "input[type='checkbox'][name='filter_ui']", function() {
    handleFilterUiFullscreen(this)
  })
  $(document).on('page:ready form:reload', function() {
    $("input[type='checkbox'][name='filter_ui']").each(function() {
      handleFilterUiFullscreen(this)
    })
  })

  // Auf Mobile den Filter erst nach kurzer Verzögerung einblenden (zwecks Aufmerksamkeit)
  $(document).on('page:ready', function() {
    const filterEl = $(".c12-search-filter")
    if (filterEl.css("position") != "fixed") return;

    filterEl.css({top: window.innerHeight, transition: "none"})
    setTimeout(function() {
      filterEl.css({top: "", transition: ""})
    }, 500)
  })
})()
